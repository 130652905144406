import { FC, useEffect, useState, useRef } from "react";
import clsx from "clsx";
import { HeaderNotificationsMenu } from "../../../partials";
import { KTIcon } from "../../../helpers";
import { getUserNotifications } from "../../../../app/modules/apps/core/services/notificationService";
import { Notification } from "../../../../app/modules/apps/core/models/notificationModels";

const toolbarButtonMarginClass = "ms-1 ms-lg-3",
  toolbarButtonHeightClass = "btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40p",
  toolbarButtonIconSizeClass = "fs-1";

function useOutsideAlerter(ref: any, callback: () => void) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, callback]);
}

const HeaderNotificationsMenuWrapper: FC = () => {
  const [notifications, setNotifications] = useState<Notification[] | undefined>();
  const [unreadNotifications, setUnreadNotifications] = useState(0);
  const [notificationsOpen, setNotificationsOpen] = useState(false);

  const loadNotifications = async () => {
    const notifications = await getUserNotifications();
    setNotifications(notifications.data);
    const readNotifications = localStorage.getItem("readNotifications")?.split(",");
    setUnreadNotifications(
      notifications.data?.filter((x) => !readNotifications?.includes(x.id || "")).length || 0
    );
  };

  useEffect(() => {
    loadNotifications();
  }, []);

  const handleNotificationOpen = () => {
    localStorage.setItem("readNotifications", notifications?.map((x) => x.id).join(",") || "");
    setNotificationsOpen(!notificationsOpen);
    setUnreadNotifications(0);
  };

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, () => setNotificationsOpen(false));

  return (
    <div className={clsx("d-flex align-items-center", toolbarButtonMarginClass)} ref={wrapperRef}>
      <div
        className={clsx(
          "btn btn-icon btn-active-light-danger btn-custom",
          toolbarButtonHeightClass
        )}
        onClick={(e) => {
          handleNotificationOpen();
        }}
      >
        <KTIcon
          iconName='notification-on'
          className={toolbarButtonIconSizeClass}
          badge={
            unreadNotifications && unreadNotifications > 0 ? (
              <span className='position-absolute top-0 start-0 translate-middle  badge badge-circle badge-danger'>
                {unreadNotifications}
              </span>
            ) : undefined
          }
        />
      </div>
      {notificationsOpen && <HeaderNotificationsMenu notifications={notifications} />}
    </div>
  );
};

export default HeaderNotificationsMenuWrapper;
