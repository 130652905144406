import axios, { AxiosResponse } from "axios";
import { ID, Response } from "../../../../../_metronic/helpers";
import { Notification, NotificationsQueryResponse } from "../models/notificationModels";

const API_URL = process.env.REACT_APP_API_URL;
const NOTIFICATION_URL = `${API_URL}/Notification`;

const getNotifications = (query: string): Promise<NotificationsQueryResponse> => {
  return axios
    .get(`${NOTIFICATION_URL}?${query}`)
    .then((d: AxiosResponse<NotificationsQueryResponse>) => d.data);
};

const getUserNotifications = (): Promise<NotificationsQueryResponse> => {
  return axios
    .get(`${NOTIFICATION_URL}/User`)
    .then((d: AxiosResponse<NotificationsQueryResponse>) => d.data);
};

const createNotification = (notification: Notification): Promise<Notification | undefined> => {
  return axios
    .post(NOTIFICATION_URL, notification)
    .then((response: AxiosResponse<Response<Notification>>) => response.data)
    .then((response: Response<Notification>) => response.data);
};

const updateNotification = (notification: Notification): Promise<Notification | undefined> => {
  return axios
    .put(`${NOTIFICATION_URL}/${notification.id}`, notification)
    .then((response: AxiosResponse<Response<Notification>>) => response.data)
    .then((response: Response<Notification>) => response.data);
};

const deleteNotification = (notification: ID): Promise<void> => {
  return axios.delete(`${NOTIFICATION_URL}/${notification}`).then(() => {});
};

export {
  getNotifications,
  getUserNotifications,
  deleteNotification,
  createNotification,
  updateNotification,
};
