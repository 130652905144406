import { lazy, FC, Suspense } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { MasterLayout } from "../../_metronic/layout/MasterLayout";
import TopBarProgress from "react-topbar-progress-indicator";
import { DashboardWrapper } from "../pages/dashboard/DashboardWrapper";
import { getCSSVariableValue } from "../../_metronic/assets/ts/_utils";
import { WithChildren } from "../../_metronic/helpers";

const PrivateRoutes = () => {
  const AccountPage = lazy(() => import("../modules/accounts/AccountPage"));
  const LockersPage = lazy(() => import("../modules/apps/locker-managment/LockersPage"));
  const MailboxPage = lazy(() => import("../modules/apps/mailbox-managment/MailboxPage"));
  const ServicePage = lazy(() => import("../modules/apps/service-managment/ServicePage"));
  const ServiceAssignmentPage = lazy(
    () => import("../modules/apps/service-assign/ServiceAssignmentPage")
  );
  const ServiceOverviewPage = lazy(
    () => import("../modules/apps/service-overview/ServiceOverviewPage")
  );
  const ServiceOverduePage = lazy(
    () => import("../modules/apps/service-overdue/ServiceOverduePage")
  );
  const WithdrawalPendingPage = lazy(
    () => import("../modules/apps/withdrawal-pending/WithdrawalPendingPage")
  );
  const WithdrawalAssignmentPage = lazy(
    () => import("../modules/apps/withdrawal-assign/WithdrawalAssignmentPage")
  );
  const WithdrawalOverviewPage = lazy(
    () => import("../modules/apps/withdrawal-overview/WithdrawalOverviewPage")
  );
  const WithdrawalOrderPage = lazy(
    () => import("../modules/apps/withdrawal-managment/WithdrawalOrderPage")
  );
  const MaintancesPage = lazy(() => import("../modules/apps/maintance-managment/MaintancesPage"));
  const MaintanceAssignmentsPage = lazy(
    () => import("../modules/apps/maintance-assign/MaintanceAssignmentsPage")
  );
  const MaintanceOverviewPage = lazy(
    () => import("../modules/apps/maintance-overview/MaintanceOverviewPage")
  );
  const DiagnosticsPage = lazy(
    () => import("../modules/apps/diagnostic-managment/DiagnosticsPage")
  );
  const RolePage = lazy(() => import("../modules/apps/role-managment/RolePage"));
  const UserPage = lazy(() => import("../modules/apps/user-managment/UserPage"));
  const ServiceDeliveredPage = lazy(
    () => import("../modules/apps/service-delivered/ServiceDeliveredPage")
  );
  const WithdrawalCompletedPage = lazy(
    () => import("../modules/apps/withdrawal-completed/WithdrawalCompletedPage")
  );
  const NotificationsPage = lazy(
    () => import("../modules/apps/notification-managment/NotificationsPage")
  );

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='locker/locker-management/*'
          element={
            <SuspensedView>
              <LockersPage />
            </SuspensedView>
          }
        />
        <Route
          path='locker/mailbox-management/*'
          element={
            <SuspensedView>
              <MailboxPage />
            </SuspensedView>
          }
        />
        <Route
          path='reports/service-managment/*'
          element={
            <SuspensedView>
              <ServicePage />
            </SuspensedView>
          }
        />
        <Route
          path='reports/service-delivered/*'
          element={
            <SuspensedView>
              <ServiceDeliveredPage />
            </SuspensedView>
          }
        />
        <Route
          path='service/service-overview/*'
          element={
            <SuspensedView>
              <ServiceOverviewPage />
            </SuspensedView>
          }
        />
        <Route
          path='service/service-overdue/*'
          element={
            <SuspensedView>
              <ServiceOverduePage />
            </SuspensedView>
          }
        />
        <Route
          path='service/withdrawal-pending/*'
          element={
            <SuspensedView>
              <WithdrawalPendingPage />
            </SuspensedView>
          }
        />
        <Route
          path='service/withdrawal-assign/*'
          element={
            <SuspensedView>
              <WithdrawalAssignmentPage />
            </SuspensedView>
          }
        />
        <Route
          path='service/withdrawal-overview/*'
          element={
            <SuspensedView>
              <WithdrawalOverviewPage />
            </SuspensedView>
          }
        />
        <Route
          path='reports/withdrawal-managment/*'
          element={
            <SuspensedView>
              <WithdrawalOrderPage />
            </SuspensedView>
          }
        />
        <Route
          path='reports/withdrawal-completed/*'
          element={
            <SuspensedView>
              <WithdrawalCompletedPage />
            </SuspensedView>
          }
        />
        <Route
          path='support/maintance-managment/*'
          element={
            <SuspensedView>
              <MaintancesPage />
            </SuspensedView>
          }
        />
        <Route
          path='support/maintance-assignment/*'
          element={
            <SuspensedView>
              <MaintanceAssignmentsPage />
            </SuspensedView>
          }
        />
        <Route
          path='support/maintance-overview/*'
          element={
            <SuspensedView>
              <MaintanceOverviewPage />
            </SuspensedView>
          }
        />
        <Route
          path='support/diagnostic-managment/*'
          element={
            <SuspensedView>
              <DiagnosticsPage />
            </SuspensedView>
          }
        />
        <Route
          path='admin/role-managment/*'
          element={
            <SuspensedView>
              <RolePage />
            </SuspensedView>
          }
        />
        <Route
          path='admin/user-managment/*'
          element={
            <SuspensedView>
              <UserPage />
            </SuspensedView>
          }
        />
        <Route
          path='service/service-assign/*'
          element={
            <SuspensedView>
              <ServiceAssignmentPage />
            </SuspensedView>
          }
        />
        <Route
          path='admin/notification-managment/*'
          element={
            <SuspensedView>
              <NotificationsPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--bs-primary");
  TopBarProgress.config({
    barColors: {
      "0": baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
