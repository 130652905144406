/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Dashboard } from "../../../modules/apps/core/models/dashboardModels";
import { KTIcon } from "../../../../_metronic/helpers";
import { useAuth } from "../../../modules/auth";

type Props = {
  className: string;
  data?: Dashboard;
};

const ServiceDashboard: React.FC<Props> = ({ className, data }) => {
  const { checkPermissions } = useAuth();

  return (
    <div className={`card ${className}`}>
      {/* begin::Beader */}
      <div className='card-header border-0 py-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3'>Servicios</span>
        </h3>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body d-flex flex-column'>
        <div>
          <div className='d-flex flex-stack mb-2'>
            <div className='d-flex align-items-center me-2'>
              <div className='symbol symbol-50px me-3'>
                <div className='symbol-label bg-light'>
                  <KTIcon
                    iconType='outline'
                    iconName='chart'
                    className='fs-2x text-success ms-n1'
                  />
                </div>
              </div>
              <div>
                <a href='#' className='fs-6 text-gray-800 text-hover-primary fw-bold'>
                  Servicios Completados
                </a>
              </div>
            </div>
            <div className='badge badge-light fw-semibold py-4 px-3'>{data?.serviceCompleted}</div>
          </div>

          {checkPermissions(["AssignService"]) && (
            <div className='d-flex flex-stack mb-5'>
              <div className='d-flex align-items-center me-2'>
                <div className='symbol symbol-50px me-3'>
                  <div className='symbol-label bg-light'>
                    <KTIcon
                      iconType='duotone'
                      iconName='wrench'
                      className='fs-2x text-warning ms-n1'
                    />
                  </div>
                </div>
                <div>
                  <a href='#' className='fs-6 text-gray-800 text-hover-primary fw-bold'>
                    Servicios Pendientes
                  </a>
                </div>
              </div>
              <div className='badge badge-light fw-semibold py-4 px-3'>{data?.serviceCreated}</div>
            </div>
          )}

          <div className='d-flex flex-stack mb-5'>
            <div className='d-flex align-items-center me-2'>
              <div className='symbol symbol-50px me-3'>
                <div className='symbol-label bg-light'>
                  <KTIcon
                    iconType='duotone'
                    iconName='chart'
                    className='fs-2x text-primary ms-n1'
                  />
                </div>
              </div>
              <div className='py-1'>
                <a href='#' className='fs-6 text-gray-800 text-hover-primary fw-bold'>
                  Servicios Activos
                </a>
              </div>
            </div>
            <div className='badge badge-light fw-semibold py-4 px-3'>{data?.serviceAssigned}</div>
          </div>

          {checkPermissions(["RetryService"]) && (
            <div className='d-flex flex-stack'>
              <div className='d-flex align-items-center me-2'>
                <div className='symbol symbol-50px me-3'>
                  <div className='symbol-label bg-light'>
                    <KTIcon
                      iconType='outline'
                      iconName='gear'
                      className='fs-2x text-danger ms-n1'
                    />
                  </div>
                </div>
                <div className='py-1'>
                  <a href='#' className='fs-6 text-gray-800 text-hover-primary fw-bold'>
                    Servicios Vencidos
                  </a>
                </div>
              </div>
              <div className='badge badge-light fw-semibold py-4 px-3'>{data?.serviceOverdue}</div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export { ServiceDashboard };
