/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from "clsx";
import { toAbsoluteUrl } from "../../../../_metronic/helpers";
import { Link } from "react-router-dom";

type Props = {
  className?: string;
  bgColor?: string;
  title?: string;
  title2?: string;
};
const CreateServiceWidget = ({ className, bgColor = "#663259", title, title2 }: Props) => {
  return (
    <div
      className={clsx("card h-175px bgi-no-repeat bgi-size-contain", className)}
      style={{
        backgroundColor: bgColor,
        backgroundPosition: "right",
        backgroundImage: `url("${toAbsoluteUrl("/media/svg/misc/taieri.svg")}")`,
      }}
    >
      <div className='card-body d-flex flex-column justify-content-between'>
        <h2 className='text-white fw-bold mb-5'>
          {title} <br /> {title2}{" "}
        </h2>

        <div className='m-0'>
          <Link
            to='/support/maintance-managment/managment'
            className='btn btn-danger fw-semibold px-6 py-3'
          >
            Crear Servicio
          </Link>
        </div>
      </div>
    </div>
  );
};

export { CreateServiceWidget };
