const QUERIES = {
  LOCKER_LIST: "lockers-list",
  SERVICE_LIST: "service-list",
  WITHDRAWAL_LIST: "withdrawal-list",
  MAILBOX_LIST: "mailbox-list",
  MAINTANCE_LIST: "maintance-list",
  DIAGNOSTIC_LIST: "diagnostic-list",
  ROLE_LIST: "role-list",
  USER_LIST: "user-list",
};

export { QUERIES };
