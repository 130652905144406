import { useIntl } from "react-intl";
import { PageTitle } from "../../../_metronic/layout/core";
import { getDashboardData } from "../../modules/apps/core/services/dashboardService";
import { Dashboard } from "../../modules/apps/core/models/dashboardModels";
import { CreateServiceWidget } from "./widgets/CreateServiceWidget";
import { CreateMaintanceWidget } from "./widgets/CreateMaintanceWidget";
import { ServiceDashboard } from "./widgets/ServiceDashboard";
import { HighlightWidget } from "./widgets/HighlightWidget";
import { useEffect, useState } from "react";
import { useAuth } from "../../modules/auth";

type Props = {
  data?: Dashboard;
};

const DashboardPage: React.FC<Props> = ({ data }) => {
  const { checkPermissions } = useAuth();
  return (
    <>
      <div className='row g-5 g-xl-8'>
        <div className='col-xl-4'>
          <ServiceDashboard className='card-xl' data={data} />
        </div>

        <div className='col-xl-8'>
          <div className='row gx-5 gx-xl-8 mb-5 mb-xl-8'>
            <div className='col-xl-4'>
              {checkPermissions(["GetMailbox"]) && (
                <HighlightWidget
                  to='/locker/mailbox-management/mailbox'
                  className='card-xxl-stretch bg-primary'
                  svgIcon='element-11'
                  titleClass='text-white'
                  descriptionClass='text-white'
                  iconClass='text-white'
                  title={data?.mailboxesAvailable.toString()}
                  description='Taquillas disponibles'
                />
              )}
            </div>
            <div className='col-xl-4'>
              {checkPermissions(["GetLocker"]) && (
                <HighlightWidget
                  to='/locker/locker-management/lockers'
                  className='card-xxl-stretch bg-info'
                  svgIcon='user'
                  titleClass='text-white'
                  descriptionClass='text-white'
                  iconClass='text-white'
                  title={data?.lockersAvailable.toString()}
                  description='Casilleros disponibles'
                />
              )}
            </div>
            <div className='col-xl-4'>
              {checkPermissions(["GetUsers"]) && (
                <HighlightWidget
                  to='/service/service-assign/services'
                  className='card-xxl-stretch bg-light'
                  svgIcon='rocket'
                  titleClass='text-dark'
                  descriptionClass='text-dark'
                  iconClass='text-success'
                  title={data?.deliveryUsers.toString()}
                  description='Mensajeros'
                />
              )}
            </div>
          </div>

          <div className='row gx-5 gx-xl-8 mb-5 mb-xl-8'>
            <div className='col-xl-6'>
              {checkPermissions(["GetService"]) && (
                <CreateServiceWidget
                  title='Agenda tu deposito'
                  className='card-xl-stretch mb-5 mb-xl-8'
                />
              )}
            </div>
            <div className='col-xl-6'>
              {checkPermissions(["GetMaintanceOrder"]) && (
                <CreateMaintanceWidget className='card-xl-stretch mb-5 mb-xl-8' />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const DashboardWrapper = () => {
  const intl = useIntl();
  const [dashboardData, setDashboardData] = useState<Dashboard>();

  const loadDashboardData = async () => {
    const response = await getDashboardData();
    setDashboardData(response || []);
  };

  useEffect(() => {
    loadDashboardData();
  }, []);
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: "MENU.DASHBOARD" })}</PageTitle>
      <DashboardPage data={dashboardData} />
    </>
  );
};

export { DashboardWrapper };
