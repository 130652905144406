import axios, { AxiosResponse } from "axios";
import { Dashboard } from "../models/dashboardModels";

const API_URL = process.env.REACT_APP_API_URL;
const DASHBOARD_URL = `${API_URL}/Dashboard`;

const getDashboardData = (): Promise<Dashboard> => {
  return axios.get(`${DASHBOARD_URL}`).then((d: AxiosResponse<Dashboard>) => d.data);
};

export { getDashboardData };
