import { Dispatch, SetStateAction } from "react";

export type ID = undefined | null | string;

export type PaginationLink = {
  label: string;
  active: boolean;
  url: string | null;
  page: number | null;
};

export type PaginationState = {
  page: number;
  pageSize: 10 | 30 | 50 | 100;
  links?: Array<PaginationLink>;
};

export type SortState = {
  sort?: string;
  order?: "asc" | "desc";
};

export type FilterState = {
  filter?: unknown;
};

export type SearchState = {
  search?: string;
};

export type Response<T> = {
  data?: T;
  totalCount?: number;
  status?: number;
};

export type QueryState = PaginationState & SortState & FilterState & SearchState;

export type QueryRequestContextProps = {
  state: QueryState;
  updateState: (updates: Partial<QueryState>) => void;
};

export const initialQueryState: QueryState = {
  page: 1,
  pageSize: 10,
};

export const initialQueryRequest: QueryRequestContextProps = {
  state: initialQueryState,
  updateState: () => {},
};

export type QueryResponseContextProps<T> = {
  response?: Response<Array<T>> | undefined;
  refetch: () => void;
  isLoading: boolean;
  query: string;
};

export const initialQueryResponse = { refetch: () => {}, isLoading: false, query: "" };

export type ListViewContextProps = {
  itemIdForUpdate?: ID;
  setItemIdForUpdate: Dispatch<SetStateAction<ID>>;
  action?: string;
  handleOpenModalWithId: (id?: ID, action?: string) => void;
};

export const initialListView: ListViewContextProps = {
  setItemIdForUpdate: () => {},
  handleOpenModalWithId: () => {},
};
