import axios from "axios";
import { AuthModel, UserModel } from "./_models";

const API_URL = process.env.REACT_APP_API_URL;

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/Auth/VerifyToken`;
export const CHANGE_USER_PASSWORD = `${API_URL}/Auth/ChangePassword`;
export const LOGIN_URL = `${API_URL}/Auth/Login`;
export const REFRESH_URL = `${API_URL}/Auth/Refresh`;

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    email,
    password,
  });
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, null, {
    headers: {
      Authorization: `bearer ${token}`,
    },
  });
}

export function refreshToken(refreshToken: string) {
  return axios.post<AuthModel>(REFRESH_URL, {
    refreshToken,
  });
}
