import { MenuItem } from "./MenuItem";
import { MenuInnerWithSub } from "./MenuInnerWithSub";
import { useIntl } from "react-intl";
import { useAuth } from "../../../../app/modules/auth";

export function MenuInner() {
  const intl = useIntl();
  const { checkPermissions } = useAuth();
  return (
    <>
      <MenuItem title={intl.formatMessage({ id: "MENU.DASHBOARD" })} to='/dashboard' />

      {checkPermissions(["GetLocker", "GetMailbox"]) && (
        <MenuInnerWithSub
          title='Casilleros'
          to='/locker'
          menuPlacement='bottom-start'
          menuTrigger='click'
        >
          {checkPermissions(["GetLocker"]) && (
            <MenuItem icon='lock' to='/locker/locker-management/lockers' title='Casilleros' />
          )}
          {checkPermissions(["GetMailbox"]) && (
            <MenuItem
              icon='electronic-clock'
              to='/locker/mailbox-management/mailbox'
              title='Taquillas'
            />
          )}
        </MenuInnerWithSub>
      )}
      {checkPermissions([
        "AssignService",
        "AssignWithdrawalOrderService",
        "CompleteServiceOrder",
        "CompleteWithdrawalOrder",
        "CreateWithdrawalOrderService",
        "RetryService",
      ]) && (
        <MenuInnerWithSub
          title='Operacion'
          to='/service/'
          menuPlacement='bottom-start'
          menuTrigger='click'
        >
          {checkPermissions(["AssignService"]) && (
            <MenuItem icon='user' to='/service/service-assign/services' title='Asignar Servicios' />
          )}
          {checkPermissions(["AssignWithdrawalOrderService"]) && (
            <MenuItem
              icon='time'
              to='/service/withdrawal-assign/order'
              title='Asignar Ordenes de Retiro'
            />
          )}
          {checkPermissions(["CompleteServiceOrder"]) && (
            <MenuItem icon='user' to='/service/service-overview/services' title='Para Depositar' />
          )}
          {checkPermissions(["CompleteWithdrawalOrder"]) && (
            <MenuItem icon='user' to='/service/withdrawal-overview/order' title='Para Retirar' />
          )}
          {checkPermissions(["CreateWithdrawalOrderService"]) && (
            <MenuItem
              icon='time'
              to='/service/withdrawal-pending/order'
              title='Servicios vencidos'
            />
          )}
          {checkPermissions(["RetryService"]) && (
            <MenuItem
              icon='time'
              to='/service/service-overdue/service'
              title='Servicios oportunidad 2'
            />
          )}
        </MenuInnerWithSub>
      )}
      {checkPermissions(["GetService", "GetWithdrawalOrder", "CompleteServiceOrder"]) && (
        <MenuInnerWithSub
          title='Reportes'
          to='/reports'
          menuPlacement='bottom-start'
          menuTrigger='click'
        >
          {checkPermissions(["CompleteServiceOrder"]) && (
            <MenuItem
              icon='calendar'
              to='/reports/service-delivered/report'
              title='Mis Servicios'
            />
          )}
          {checkPermissions(["CompleteWithdrawalOrder"]) && (
            <MenuItem
              icon='calendar'
              to='/reports/withdrawal-completed/report'
              title='Mis Retiros'
            />
          )}
          {checkPermissions(["GetService"]) && (
            <MenuItem
              icon='calendar'
              to='/reports/service-managment/report'
              title='Historial de Servicios'
            />
          )}
          {checkPermissions(["GetWithdrawalOrder"]) && (
            <MenuItem
              icon='calendar'
              to='/reports/withdrawal-managment/report'
              title='Historial de Ordenes de Retiro'
            />
          )}
        </MenuInnerWithSub>
      )}
      {checkPermissions(["GetMaintanceOrder", "GetDiagnostic"]) && (
        <MenuInnerWithSub
          title='Mantenimientos'
          to='/support'
          menuPlacement='bottom-start'
          menuTrigger='click'
        >
          {checkPermissions(["GetMaintanceOrder"]) && (
            <MenuItem
              icon='calendar'
              to='support/maintance-managment/managment'
              title='Mantenimientos'
            />
          )}
          {checkPermissions(["AssignMaintanceOrder"]) && (
            <MenuItem
              icon='user'
              to='support/maintance-assignment/assignment'
              title='Asignar Mantenimiento'
            />
          )}
          {checkPermissions(["CompleteMaintanceOrder"]) && (
            <MenuItem
              icon='user'
              to='/support/maintance-overview/overview'
              title='Realizar Mantenimiento'
            />
          )}
          {checkPermissions(["GetDiagnostic"]) && (
            <MenuItem
              icon='calendar'
              to='support/diagnostic-managment/managment'
              title='Diagnosticos'
            />
          )}
        </MenuInnerWithSub>
      )}
      {checkPermissions(["GetRoles", "GetUsers", "GetUsersManagment", "GetNotification"]) && (
        <MenuInnerWithSub
          title='Administracion'
          to='/admin'
          menuPlacement='bottom-start'
          menuTrigger='click'
        >
          {checkPermissions(["GetRoles"]) && (
            <MenuItem icon='book' to='admin/role-managment/role' title='Roles' />
          )}
          {checkPermissions(["GetUsers", "GetUsersManagment"]) && (
            <MenuItem icon='user' to='admin/user-managment/user' title='Usuarios' />
          )}
          {checkPermissions(["GetNotification"]) && (
            <MenuItem icon='flag' to='admin/notification-managment/index' title='Notificaciones' />
          )}
        </MenuInnerWithSub>
      )}
    </>
  );
}
