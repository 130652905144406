/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from "clsx";
import { KTIcon } from "../../../../_metronic/helpers";
import { Link } from "react-router-dom";

type Props = {
  to: string;
  className?: string;
  svgIcon?: string;
  titleClass?: string;
  descriptionClass?: string;
  iconClass?: string;
  title?: string;
  description?: string;
};
const HighlightWidget = (props: Props) => {
  const { className, svgIcon, titleClass, descriptionClass, iconClass, title, description, to } =
    props;
  return (
    <Link to={to?.toString()} className={clsx("card", className)}>
      <div className='card-body d-flex flex-column justify-content-between'>
        <KTIcon iconName={svgIcon || ""} className={clsx(iconClass, "fs-2hx ms-n1 flex-grow-1")} />
        <div className='d-flex flex-column'>
          <div className={clsx(titleClass, "fw-bold fs-1 mb-0 mt-5")}>{title}</div>
          <div className={clsx(descriptionClass, "fw-semibold fs-6")}>{description}</div>
        </div>
      </div>
    </Link>
  );
};

export { HighlightWidget };
