/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from "clsx";
import { KTIcon, toAbsoluteUrl } from "../../../../_metronic/helpers";
import { Link } from "react-router-dom";

type Props = {
  className?: string;
  bgColor?: string;
  title?: string;
  title2?: string;
};
const CreateMaintanceWidget = ({ className }: Props) => {
  return (
    <div
      className={clsx("card h-175px bgi-no-repeat bgi-size-cover", className)}
      style={{ backgroundImage: `url("${toAbsoluteUrl("/media/misc/bg-2.jpg")}")` }}
    >
      {/* begin::Body */}
      <div className='card-body d-flex flex-column justify-content-between'>
        {/* begin::Title */}
        <div className='text-white fw-bold fs-2'>
          <h2 className='fw-bold text-white mb-2'>¿Problemas con tu entrega?</h2>
          Podemos ayudar.
        </div>
        {/* end::Title */}

        {/* begin::Link */}
        <Link to='/reports/service-managment/report' className='text-warning fw-semibold'>
          Crear un reporte
          <KTIcon className='fs-2 text-warning' iconName='arrow-right' />
        </Link>
        {/* end::Link */}
      </div>
      {/* end::Body */}
    </div>
  );
};

export { CreateMaintanceWidget };
