/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react";
import { toAbsoluteUrl } from "../../../helpers";
import { Notification } from "../../../../app/modules/apps/core/models/notificationModels";
import moment from "../../../helpers/Moment";
type Props = {
  notifications?: Notification[];
};

const HeaderNotificationsMenu: FC<Props> = ({ notifications }) => {
  return (
    <div
      className='menu menu-sub menu-sub-dropdown menu-column w-350px w-lg-375px show'
      data-kt-menu='true'
      style={{
        zIndex: 105,
        position: "fixed",
        inset: "0px 0px auto auto",
        margin: 0,
        transform: "translate(-715px, 61px)",
      }}
      onFocus={() => {
        console.log("test");
      }}
    >
      <div
        className='d-flex flex-column bgi-no-repeat rounded-top'
        style={{ backgroundImage: `url('${toAbsoluteUrl("/media/misc/menu-header-bg.jpg")}')` }}
      >
        <h3 className='text-white fw-semibold px-9 mt-10 mb-6'>
          Notificaciones <span className='fs-8 opacity-75 ps-3'>{notifications?.length}</span>
        </h3>
      </div>

      <div>
        <div>
          <div className='scroll-y mh-325px my-5 px-8'>
            {notifications?.map((notification, index) => (
              <>
                <div key={`notification${index}`} className='d-flex flex-stack py-4'>
                  <div className=' align-items-left '>
                    <span className='badge badge-light fs-9 mb-2'>
                      {moment
                        .utc(notification.updatedAt)
                        .local()
                        .format("dddd Do MMM YYYY HH:MM:ss")}
                    </span>
                    <div className='fs-4 mb-2'>{notification.title}</div>
                    <div>{notification.description}</div>
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export { HeaderNotificationsMenu };
