/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react";
import clsx from "clsx";
import { toAbsoluteUrl } from "../../../helpers";
import { HeaderUserMenu, ThemeModeSwitcher } from "../../../partials";
import HeaderNotificationsMenuWrapper from "./HeaderNotificationsMenuWrapper";

const toolbarButtonMarginClass = "ms-1 ms-lg-3",
  toolbarButtonHeightClass = "btn-active-light-primary btn-custom w-30px h-30px w-md-40px h-md-40p",
  toolbarUserAvatarHeightClass = "symbol-30px symbol-md-40px";

const Topbar: FC = () => {
  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      <div className='topbar d-flex align-items-stretch flex-shrink-0'>
        <HeaderNotificationsMenuWrapper />
        <div className={clsx("d-flex align-items-center", toolbarButtonMarginClass)}>
          <ThemeModeSwitcher toggleBtnClass={toolbarButtonHeightClass} />
        </div>
        <div
          className={clsx("d-flex align-items-center", toolbarButtonMarginClass)}
          id='kt_header_user_menu_toggle'
        >
          <div
            className={clsx("cursor-pointer symbol", toolbarUserAvatarHeightClass)}
            data-kt-menu-trigger='click'
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='bottom'
          >
            <img
              className='h-30px w-30px rounded'
              src={toAbsoluteUrl("/media/avatars/blank.png")}
              alt='metronic'
            />
          </div>
          <HeaderUserMenu />
        </div>
      </div>
    </div>
  );
};

export { Topbar };
